.container {
    margin-top: 4em;

    .back-link {
        width: fit-content;
        margin-bottom: 1.5em;
        cursor: pointer;

        .back-icon {
            padding: 0.25em 0;
            display: flex;
            align-items: center;
            line-height: 1;
            color: var(--color-gray-600);
            gap: 0.25em;
        }
    }

    .activity-wrapper {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 1em;

        .status-wrapper {
            box-shadow: 0 2px 4px -2px #363a3e0f;
            padding: 0 2.375em 0.625em;
            background-color: var(--color-white);

            .completed-link {
                cursor: pointer;
                color: var(--color-blue-600);
                padding: 0.75em 0 1.25em;

                &:hover {
                    color: var(--color-blue-700);
                }
            }
        }
    }
}
