.status-header {
    margin-bottom: 0.75em;

    .status {
        padding: 0.375em 1em;
        border-bottom-left-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
        width: fit-content;

        &.overdue {
            background-color: #d602021a;
            color: var(--color-red);
        }

        &.progress {
            background-color: #4285f41a;
            color: var(--color-accent-6);
        }

        &.coming {
            background-color: #f49e4c14;
            color: var(--color-accent-1);
        }

        &.completed {
            background-color: #299d4214;
            color: var(--color-dark-green);
        }
    }
}

.activity {
    cursor: pointer;
    display: flex;
    border-bottom: 1px solid var(--color-gray-300);
    justify-content: space-between;
    align-items: center;
    padding: 0.875em 0;
    gap: 5em;
    margin-bottom: 0.25em;

    .title {
        color: var(--color-gray-600);
    }

    &:hover {
        .title {
            color: var(--color-primary);
        }
    }

    .right-content {
        display: flex;
        align-items: center;
        gap: 2em;

        .date {
            width: max-content;
            color: var(--color-gray-600);
        }

        .add-button {
            width: 12.25em;
        }
    }
}
