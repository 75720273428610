.sidebar-container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1.25rem 0.625rem 1.75rem;
    background-color: var(--color-white);
    height: 100vh;
    width: 15rem;
    z-index: 2;

    .nav-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .logo-container {
            margin-left: 1.625rem;
            max-width: 7.8rem;
            height: 2.25rem;

            .logo-title {
                color: var(--color-blue-700);
            }
        }

        .menu-toggle {
            padding: 10px 5px;
            border-radius: 6px;
            display: none;

            .menu-toggle-icon {
                position: relative;

                &,
                &::before,
                &::after {
                    width: 25px;
                    height: 2px;
                    transition: all 0.3s ease-in-out;
                    background-color: var(--color-text);
                }

                &::before,
                &::after {
                    position: absolute;
                    content: '';
                    transition: all 0.3s;
                }

                &::before {
                    top: -7px;
                }

                &::after {
                    top: 7px;
                }
            }
        }

        .nav-items {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 3.37rem;
            gap: 0.75rem;

            .nav-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0.75rem 1.625rem;
                width: 100%;
                cursor: pointer;

                &:hover {
                    background-color: var(--color-background);
                    border-radius: 8px;
                }

                .item-name {
                    color: var(--color-gray-600);
                }

                .nav-icon {
                    display: flex;
                    margin-right: 0.875rem;

                    .icon-style {
                        height: 20px;
                        width: 20px;
                        color: var(--color-black);
                    }
                }

                .svg-icon-active {
                    display: none;
                }
            }

            .active-item {
                background-color: var(--color-background);
                border-radius: 8px;

                .item-name {
                    color: var(--color-primary);
                }

                .nav-icon {
                    .icon-style {
                        color: var(--color-primary);
                    }
                }

                .svg-icon {
                    display: none;
                }

                .svg-icon-active {
                    display: inline;
                }
            }
        }
    }
}

@media only screen and (max-width: 720px) {
    .sidebar-container {
        display: flex;
        height: 3.75rem;
        align-items: flex-start;
        justify-content: flex-start;
        width: unset;
        padding: 2rem 1rem;

        .nav-content {
            .menu-toggle {
                margin: unset;
                display: flex;
                align-items: center;
                justify-content: center;

                .menu-toggle-icon {
                    display: inline-block;
                }

                &:hover {
                    cursor: pointer;

                    .menu-toggle-icon::before {
                        top: -8px;
                    }

                    .menu-toggle-icon::after {
                        top: 8px;
                    }
                }
            }

            .nav-items {
                position: fixed;
                left: -100%;
                flex-direction: column;
                align-items: center;
                text-align: center;
                gap: 12px;
            }

            .logo-container {
                display: none;
            }
        }

        .user-section {
            display: none;
        }
    }

    .container {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 3;

        .sidebar-container {
            height: 100vh;
            padding-bottom: 2rem;
            box-shadow: 0 2px 5px rgb(70 67 73 / 16%);

            .nav-content {
                .logo-container {
                    display: none;
                }

                .menu-toggle {
                    align-self: flex-start;

                    .menu-toggle-icon {
                        background-color: transparent;

                        &::before {
                            top: 0;
                            transform: rotate(45deg);
                            background-color: var(--color-black);
                        }

                        &::after {
                            top: 0;
                            transform: rotate(-45deg);
                            background-color: var(--color-black);
                        }

                        &:hover {
                            cursor: pointer;

                            .menu-toggle-icon::before {
                                top: 0;
                            }

                            .menu-toggle-icon::after {
                                top: 0;
                            }
                        }
                    }
                }

                .nav-items {
                    position: relative;
                    display: flex;
                    left: 0;
                    top: 0;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    transition: all 0.5s ease;

                    .nav-item {
                        color: var(--color-black);
                        padding: 5px 10px;
                    }

                    .active-item {
                        color: var(--color-primary);
                    }
                }
            }

            .user-section {
                display: block;
            }
        }
    }
}
