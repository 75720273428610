.empty-wrapper {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75em;
    padding: 2em 3em;
    border-radius: 0.5em;
    background-color: var(--color-white);

    .file-icon {
        color: var(--color-blue-400);
        font-size: 4em;
    }

    &.small {
        flex-direction: row;
        margin: unset;
        padding: unset;

        .file-icon {
            font-size: 1.5em;
        }
    }
}
