.container {
    border: 1px solid #f4f4f4;
    width: calc(100vw - 616px);
    max-width: 964px;
    height: 390px;
    background-color: var(--color-white);
    padding: 1.5rem 3rem 1.5rem 1.5rem;
    box-shadow: 0 2px 4px -2px rgb(54 58 62 / 6%);
    position: relative;

    .content {
        background-color: var(--color-primary);
        width: 270px;
        border: none;
        border-radius: 4px;
        padding: 0.5rem;
        color: var(--color-white);
        position: relative;

        &::before {
            content: '';
            position: absolute;
            bottom: 100%;
            left: 50%;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent var(--color-primary) transparent;
        }
    }

    .bar-legend {
        margin-left: 270px;
        text-align: center;
        color: var(--color-gray-600);
    }

    .bar-chart {
        margin-top: 0.25em;
    }
}
