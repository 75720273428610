.container {
    margin: 4em auto auto;

    .main {
        margin: 0 auto;
        max-width: 47.5em;
        width: 97%;
        background-color: var(--color-white);
        border-radius: 0.25em;
        box-shadow: 0 2px 4px -2px #363a3e0f;
        padding: 1em 2em 1.25em;
    }

    .pagination-wrapper {
        margin-top: 2em;
        display: flex;
        justify-content: flex-end;

        .pagination {
            box-shadow: none;
            gap: 0.5em;

            .pagination-control {
                &:hover {
                    background-color: unset;
                }
            }

            .pagination-control-item {
                display: flex;
                align-items: center;

                &-left {
                    margin-right: 0.875em;
                }

                &-right {
                    margin-left: 0.875em;
                }

                .control-title {
                    color: var(--color-gray-500);
                    font-size: 1em;
                }
            }

            .pagination-item {
                font-size: 0.875rem;
                line-height: 1.428em;
                border-radius: 0.25em;
                min-height: 1.25em;
                min-width: 1.25em;

                &.active {
                    background-color: var(--color-primary-600);
                }
            }
        }
    }
}
