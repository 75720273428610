.container {
    width: 50%;
    max-height: 100vh;
    overflow: hidden;
    background: linear-gradient(155.67deg, #006eb5 0%, rgb(0 110 181 / 34%) 113.01%);
    padding: 7.25em 0 0 5em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .heading {
        color: var(--color-white);
        max-width: 19.5em;
    }

    .dotted-icon {
        margin: 3.5em 0 4.875em;
    }

    .image-wrapper {
        height: fit-content;
        display: flex;
        justify-content: flex-end;

        .dashboard-image {
            height: fit-content;
            height: 70vh;
            object-fit: contain;
        }
    }
}
