.beneficiary-item {
    width: 100%;
    min-width: fit-content;

    .title {
        background-color: var(--color-background);
        color: var(--color-gray-600);
        padding: 0.5em 1em 0.5em 0.5em;
    }

    .value {
        padding: 0.5em;
        color: var(--color-gray-600);
    }

    &:first-child {
        .title,
        .value {
            padding-left: 0.75em;
        }
    }

    &:last-child {
        .title,
        .value {
            padding-right: 0.75em;
        }
    }
}

.select {
    .select-control {
        border: none !important;
        padding: 0;

        :global {
            .select-value {
                font-weight: 600;
                color: var(--color-blue-600);
            }
        }

        &.completed {
            :global {
                .select-value {
                    color: var(--color-dark-green);
                }
            }
        }
    }
}

.options-wrapper {
    padding: 0;
    margin-top: 1em;

    .select-option {
        background-color: var(--color-white);
        box-shadow: 0 0 2px 1px #00000014;
        border-radius: 0.25em;
        padding: 0.5em 0 !important;
    }

    .option-item {
        padding: 0.5em 1.5em;
        font-size: 0.875em;
        line-height: 1.25em;
        font-weight: 600;
        color: var(--color-gray-600);
        background-color: var(--color-white);
    }
}

.modal {
    background-color: var(--color-white);
    padding: 1.75em 2.5em;
    width: 57.5em;
    border-radius: 1.5em;
    overflow-y: auto;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.25em;

        .close-icon {
            cursor: pointer;
        }
    }

    .top-bar {
        display: flex;
        justify-content: space-between;
        gap: 2.5em;
        border-bottom: 1px solid #d4d6d8;
        padding-bottom: 0.875em;

        .info-item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 0.5em;
            width: max-content;

            .field {
                color: var(--color-gray-700);
            }

            .title {
                color: var(--color-gray-700);
            }

            .value-wrapper {
                height: 100%;
                display: flex;
                align-items: center;

                .date {
                    color: var(--color-gray-600);
                }
            }

            .status {
                color: var(--color-accent-6);

                &.completed {
                    color: var(--color-dark-green);
                }
            }
        }

        .button-wrapper {
            margin-top: 1.5em;
            display: flex;
            align-items: center;

            .add-button {
                width: max-content;
            }
        }
    }

    .description {
        margin-top: 1.5em;
    }

    .beneficiaries-wrapper {
        display: flex;
        justify-content: space-between;
        flex: 1;
        border: 1px solid #f2f5f7;
        border-radius: 0.25em;
        overflow: auto;
    }

    .report-beneficiaries-wrapper {
        display: flex;
        border: 1px solid #f2f5f7;
        border-radius: 0.25em;
        overflow-x: auto;
        width: 100%;
    }

    .region-activity-content {
        margin-top: 1.5em;

        .tab-content {
            margin-top: 1.5em;

            .tab-top-content {
                display: flex;
                align-items: center;
                gap: 2.5em;
                margin-bottom: 1.5em;

                .date-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 0.25em;

                    .title {
                        color: var(--color-gray-600);
                    }
                }
            }

            .hair-line {
                margin-top: 1.5em;
                border-bottom: 2px solid var(--color-gray-400);
                width: 100%;
            }

            .active-report-status {
                display: flex;
                align-items: center;
                gap: 0.5em;
                width: fit-content;

                .field {
                    color: var(--color-gray-600);
                }
            }

            .content-heading {
                color: var(--color-gray-600);
                margin-top: 1.5em;
                margin-bottom: 0.5em;
            }

            .report-heading {
                margin-top: 1em;
            }

            .report-card {
                margin-top: 1em;
                padding: 1em;
                box-shadow: rgb(0 0 0 / 10%) 0 1px 2px 0;

                .report-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .report-item {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        width: max-content;

                        .field {
                            color: var(--color-gray-700);
                        }

                        .title {
                            color: var(--color-gray-700);
                            margin-bottom: 0.75em;
                        }

                        .organization-name {
                            color: var(--color-blue-200);
                            margin-bottom: 0.75em;
                        }
                    }
                }

                .submitted-info {
                    margin-top: 1em;
                    display: flex;
                    flex-direction: column;
                    gap: 0.25em;

                    .info-item {
                        display: flex;
                        gap: 0.5em;
                        align-items: center;

                        .field {
                            color: var(--color-gray-600);
                            width: 100%;
                            max-width: fit-content;
                        }

                        .full-name {
                            color: var(--color-gray-600);
                        }

                        .file-name {
                            cursor: pointer;
                            color: var(--color-accent-6);
                        }
                    }
                }
            }
        }
    }
}
