.tab-header {
    background-color: var(--color-white);
    overflow-x: auto;
    border-bottom: 1px solid var(--color-gray-400);

    .tab-header-item {
        transition: background-color 0.2s ease-out;
        min-width: fit-content;
        padding: 0.75em 0;
        margin-right: 1.625em;
        font-size: 1em;
        line-height: 1.5em;
        font-weight: 600;
        color: var(--color-gray-600);

        &-active {
            border-bottom: 3px solid var(--color-blue-600);
            color: var(--color-blue-600);
        }
    }
}
