.container {
    display: flex;
    width: 100%;
    justify-content: center;
    max-width: calc(60vw + 404px);

    .supported {
        display: flex;
        align-items: center;

        .logo-wrapper {
            padding-right: 32px;
            border-right: 2px solid #d4d6d8;
            display: flex;
            align-items: center;
            justify-content: center;

            .logo {
                height: 80px;
            }
        }
    }

    .funded {
        display: flex;
        align-items: center;

        .logo-wrapper {
            padding: 0 2em;
            border-right: 2px solid #d4d6d8;

            .logo {
                height: 85px;
            }
        }
    }

    .partners-wrapper {
        padding-left: 2em;
        display: flex;

        .partner-logo-wrapper {
            display: flex;
            gap: 3.5em;

            .partner-logo {
                height: 90px;
            }

            .unicef-logo {
                height: 95px;
            }

            .un-women-logo {
                margin-top: 8px;
                height: 40px;
            }
        }
    }
}
