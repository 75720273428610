.button {
    border: none;
    border-radius: 100px;
    padding: 0.5rem 1rem;
    filter: none !important;

    .content {
        display: flex;
        align-items: center;
        justify-content: center;

        .left-icon {
            font-size: 1.25em;
            margin: 0 0.5rem;
        }

        .right-icon {
            font-size: 1.25em;
            margin: 0 0.5rem;
        }
    }

    &:hover {
        background-color: var(--color-blue-700);
    }

    &:focus {
        box-shadow: 0 0 0 4px #d4d6d8;
        outline: none;
    }

    &-disabled {
        background-color: var(--color-blue-200);
    }

    &-secondary {
        background-color: var(--color-white);
        border: 1px solid var(--color-primary);
        color: var(--color-primary);

        &:hover {
            border-color: var(--color-blue-700);
            color: var(--color-blue-700);
            background-color: var(--color-white);
        }

        &-disabled {
            border-color: var(--color-blue-200);
            color: var(--color-blue-200);
        }
    }

    &-subtle {
        background-color: var(--color-white);
        border: 1px solid var(--color-gray);
        color: var(--color-primary);

        &:hover {
            color: var(--color-blue-700);
            background-color: var(--color-white);
        }

        &-disabled {
            color: var(--color-blue-200);
        }
    }

    &-text {
        background-color: var(--color-white);
        color: var(--color-primary);

        &:hover {
            color: var(--color-blue-700);
            background-color: var(--color-white);
        }

        &:focus {
            box-shadow: none;
            outline: initial;
            background-color: #006eb529;
        }

        &-disabled {
            color: var(--color-blue-200);
        }
    }
}

.button-left-icon {
    padding-left: 0.5rem;
}

.button-right-icon {
    padding-right: 0.5rem;
}

.button-no-icon {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
}
