.main {
    padding: 1.25rem;
    background-color: var(--color-background);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
        position: absolute;
        top: 1.25em;
        left: 1.25em;
        color: var(--color-blue-700);
    }
}
