.change-password-card {
    background-color: var(--color-white);
    padding: 2.5em 2em;
    border-radius: 0.5em;

    .form-wrapper {
        margin-top: 2em;

        .error-message,
        .error-message * {
            color: var(--color-dark-red);
            text-align: center;
            margin-top: 1rem;
        }

        .input-wrapper {
            display: flex;
            flex-direction: column;
            gap: 1.25em;

            .label {
                margin-bottom: 0.5em;
                color: var(--color-gray-500);
                font-weight: 400;
            }

            .input {
                border-radius: 0.5em;
            }
        }

        .auth-button {
            margin-top: 2em;
            width: 100%;
        }
    }

    .forgot-password-link {
        width: fit-content;
        text-align: center;
        color: var(--color-blue-600);
        margin: 1em auto 0;
        cursor: pointer;
    }
}
