.modal {
    background-color: var(--color-white);
    padding: 2em 2.5em;
    width: 50em;
    border-radius: 1.5em;
    overflow-y: auto;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2.25em;

        .close-icon {
            cursor: pointer;
        }
    }

    .form-wrapper {
        display: flex;
        flex-direction: column;
        gap: 2em;

        .error-message,
        .error-message * {
            color: var(--color-dark-red);
            text-align: center;
            margin-top: 1rem;
        }

        .select-wrapper {
            .selectLabel {
                margin-bottom: 0.25em;
                color: var(--color-gray-700);
            }

            .select {
                .select-control {
                    height: 3em;
                    border: 1px solid var(--color-gray-400);
                }
            }
        }

        .input-wrapper {
            display: flex;
            flex-direction: column;
            gap: 2em;

            .label {
                margin-bottom: 0.25em;
                color: var(--color-gray-700);
                font-weight: 600;
                font-size: 1em;
                line-height: 1.5em;
            }

            .input {
                width: 100%;
                border: 1px solid var(--color-gray-400);
                color: var(--color-gray-600);
                height: 3em;
                padding: 1em;
            }

            .textarea-label {
                &::after {
                    content: '*';
                    color: var(--color-red);
                    margin-left: 0.125em;
                }
            }

            .textarea {
                padding: 1em;
                width: 100%;
                border: 1px solid var(--color-gray-400);
                border-radius: 0.25em;
                font-size: 1em;
                line-height: 1.5em;
                color: var(--color-gray-600);
            }
        }

        .form-label {
            color: var(--color-gray-700);
            margin-bottom: 0.5em;

            &::after {
                content: '*';
                color: var(--color-red);
                margin-left: 0.125em;
            }
        }

        .small-input-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            row-gap: 1em;

            .input-field-container {
                width: 15.5%;

                .small-input-label {
                    color: var(--color-gray-600);
                    margin-left: 2px;
                    font-size: 0.75em;
                    font-weight: 600;
                    margin-bottom: 0.25em;
                }

                .small-input {
                    .input {
                        height: 2.5em;
                        width: 100%;
                        color: var(--color-gray-600);
                        border: 1px solid var(--color-gray-400);
                        padding: 1em;
                        appearance: textfield;

                        &::-webkit-inner-spin-button {
                            appearance: none;
                            margin: 0;
                        }
                    }

                    .read-only-input {
                        height: 2.5em;
                        width: 100%;
                        padding: 1em;
                        border: 1px solid var(--color-gray-400);
                        background-color: var(--color-gray-300);
                        color: var(--color-gray-600);
                    }
                }
            }
        }

        .file-input-label {
            margin-bottom: 1em;
            color: var(--color-gray-700);
            font-weight: 600;
            font-size: 1em;
            line-height: 1.5em;
        }

        .button-wrapper {
            margin-top: 0.25em;
            display: flex;
            justify-content: flex-end;
        }
    }
}
