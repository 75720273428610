.back-button {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-bottom: 0.75em;

    .title {
        color: var(--color-gray-600);
    }

    .icon {
        color: var(--color-gray-600);
        font-size: 1.25em;
    }
}

.login-box {
    background-color: var(--color-white);
    padding: 2.5em 2em;
    box-shadow: 0 2px 4px -2px #363a3e0f;
    border-radius: 0.5em;
    width: 30em;

    .heading {
        color: var(--color-gray-700);
        margin-bottom: 0.5em;
    }

    .sub-heading {
        color: var(--color-gray-500);

        span {
            color: var(--color-blue-600);
        }
    }

    .form-wrapper {
        margin-top: 2em;

        .error-message,
        .error-message * {
            color: var(--color-dark-red);
            margin-top: 1rem;
        }

        .input-wrapper {
            .otp-container {
                display: flex;
                gap: 1em;
                justify-content: space-between;

                .otp-input {
                    height: 56px;
                    width: 56px;
                    border-radius: 0.5em;
                    display: flex;
                    justify-items: center;
                    align-items: center;
                    text-align: center;
                    border: 1px solid var(--color-gray-400);

                    &-error {
                        border: 1px solid var(--color-red);
                    }

                    &::-webkit-inner-spin-button {
                        appearance: none;
                        margin: 0;
                    }

                    &:focus,
                    &:hover {
                        border: 1px solid var(--color-blue-400);
                    }
                }
            }
        }

        .resend-link {
            cursor: pointer;
            width: fit-content;
            display: flex;
            margin-top: 1.5em;
            gap: 0.25em;

            .message {
                color: var(--color-gray-600);
            }

            .button {
                color: var(--color-blue-700);
            }
        }

        .auth-button {
            margin-top: 1.5em;
        }
    }
}
