.container {
    margin-top: 4em;

    .activity-wrapper {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 1em;

        .status-wrapper {
            box-shadow: 0 2px 4px -2px #363a3e0f;
            padding: 0 2.375em 0.75em;
            background-color: var(--color-white);
            border-radius: 0.25em;

            .link-wrapper {
                width: fit-content;

                .completed-link {
                    cursor: pointer;
                    color: var(--color-blue-600);
                    padding: 0.75em 0;

                    &:hover {
                        color: var(--color-blue-700);
                    }
                }
            }
        }
    }
}
