.typography {
    font-weight: 400;
    line-height: 1.2em;

    &.bold {
        font-weight: 700;
    }

    &.semibold {
        font-weight: 600;
    }

    &.light {
        font-weight: 300;
    }

    &.display {
        &1 {
            font-size: 4.5rem;
        }

        &2 {
            font-size: 3.75rem;

            &.bold {
                letter-spacing: -0.01em;
            }
        }
    }

    &.heading {
        &1 {
            font-size: 3rem;
        }

        &2 {
            font-size: 2.25rem;
        }

        &3 {
            font-size: 1.75rem;
            line-height: 1.29em;
        }

        &4 {
            font-size: 1.5rem;
            line-height: 1.25em;
        }
    }

    &.body {
        &1 {
            font-size: 1.25rem;
            line-height: 1.5em;
        }

        &2 {
            font-size: 1.125rem;
            line-height: 1.25em;
        }

        &3 {
            font-size: 1rem;
            line-height: 1.5rem;
        }

        &4 {
            font-size: 0.875rem;
            line-height: 1.43em;
        }

        &5 {
            font-size: 0.75em;
            line-height: 1.5em;
        }
    }
}
