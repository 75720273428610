.container {
    margin-top: 4em;

    .dashboard-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1rem;
        width: fit-content;

        .header {
            display: flex;
            gap: 1rem;
            width: 100%;
        }

        .summary {
            display: flex;
            justify-content: flex-start;
            border-radius: 4px;
            gap: 1rem;
            width: 100%;
            max-width: 1300px;

            .item {
                flex: 1;
                display: flex;
                flex-direction: row;
                gap: 1rem;
                padding: 0.875rem 1rem 0.875rem 1.5rem;
                border-radius: 4px;
                background-color: var(--color-white);
                align-items: center;
                box-shadow: 0 2px 4px -2px rgb(54 58 62 / 6%);

                @media only screen and (max-width: 1080px) {
                    min-width: unset;
                }

                .icon-container {
                    display: flex;
                    background-color: var(--color-blue-500);
                    height: 3.5rem;
                    width: 3.5rem;
                    align-items: center;
                    justify-content: center;
                    border-radius: 8px;

                    .icon {
                        height: 24px;
                        width: 24px;
                        color: var(--color-white);
                    }
                }

                .item-info {
                    display: flex;
                    flex-direction: column;

                    .item-name {
                        color: var(--color-gray-500);
                    }
                }
            }
        }

        .charts {
            display: flex;
            flex-flow: row wrap;
            gap: 1rem;
        }

        .count-chart-wrapper {
            display: flex;
            gap: 1em;
        }

        .bottom-content {
            display: flex;
            flex-flow: row wrap;
            gap: 1rem;
        }
    }
}
