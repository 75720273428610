.container {
    margin-top: 4em;

    .gantt-container {
        padding: 1.5rem;
        background-color: var(--color-white);
        position: relative;

        .title-section {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.5rem;

            .view-mode {
                display: flex;

                .view-button {
                    flex: 1;
                    color: var(--color-gray-600);
                    box-shadow: none;
                    transition: unset;

                    &:hover {
                        background-color: var(--color-primary);
                        color: var(--color-white);
                        border: 1px solid;
                        border-color: var(--color-primary) var(--color-gray-300);
                    }
                }

                .left-button {
                    border-radius: 4px 0 0 4px;
                    border-right: none;
                }

                .middle-button {
                    border-radius: 0;
                }

                .right-button {
                    border-radius: 0 4px 4px 0;
                    border-left: none;
                }

                .button-clicked {
                    background-color: var(--color-primary);
                    color: var(--color-white);
                    border: 1px solid var(--color-primary);
                    padding-right: 2.25rem;
                }
            }
        }

        .task-list-wrapper {
            display: table;
            position: relative;
            border-right: 1px solid var(--color-gray-300);

            .task-list-table-row {
                display: table-row;
                white-space: nowrap;

                .task-list-cell {
                    display: table-cell;
                    vertical-align: middle;

                    .task-list-name-wrapper {
                        display: flex;
                        align-items: center;

                        .task-list-expander {
                            color: var(--color-gray-600);
                            font-size: 0.6rem;
                            padding: 0.15rem 0.7rem 0 0.2rem;
                            cursor: pointer;

                            .arrow-button {
                                height: 12px;
                                width: 12px;
                                transition: all 0.3s ease;
                            }

                            .arrow-button-down {
                                transform: rotateZ(90deg);
                            }
                        }

                        .hover-container {
                            background-color: var(--color-primary);
                            width: 330px;
                            border: none;
                            border-radius: 4px;
                            padding: 0.5rem;
                            white-space: normal;
                            position: absolute;
                            color: var(--color-white);
                            margin-top: 8px;
                            margin-left: -1rem;

                            &::before {
                                content: '';
                                position: absolute;
                                bottom: 100%;
                                left: 50%;
                                border-width: 5px;
                                border-style: solid;
                                border-color: transparent transparent var(--color-primary)
                                    transparent;
                            }
                        }

                        .project-task-name {
                            font-size: 1rem;
                            line-height: 1.5;
                            font-weight: 600;
                            color: var(--color-gray-600);
                            cursor: default;
                        }

                        .task-name {
                            padding-left: 2.5rem;
                            font-size: 0.875rem;
                            line-height: 1.25;
                            font-weight: 400;
                            color: var(--color-gray-500);
                            cursor: default;

                            .hover-container {
                                margin-left: -2rem;
                            }
                        }
                    }
                }
            }
        }

        .legend {
            display: flex;
            flex-flow: row wrap;
            list-style: none;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            gap: 2rem;

            .item {
                display: flex;
                gap: 0.5rem;
                align-items: center;
                height: 1.5rem;

                .color-box {
                    border-radius: 4px;
                    height: 16px;
                    width: 16px;
                }

                .item-title {
                    color: var(--color-gray-600);
                }
            }
        }
    }
}

:global {
    ._2k9Ys {
        display: none;
    }

    ._CZjuD {
        overflow: auto;
    }

    ._2B2zv {
        overflow: visible;
    }

    ._3zRJQ {
        display: none;
    }

    ._3KcaM {
        display: none;
    }

    ._2dZTy:nth-child(even) {
        fill: var(--color-white);
    }

    ._1KJ6x {
        cursor: default;
    }

    ._KxSXS {
        cursor: default;
    }
}
