.legend {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    align-items: center;
    padding-left: 2rem;
    justify-content: space-between;
    row-gap: 0.75em;

    .item {
        display: flex;
        flex: 50%;
        gap: 0.5rem;
        align-items: center;

        .color-box {
            border-radius: 4px;
            height: 16px;
            width: 16px;
        }

        .item-title {
            color: var(--color-gray-600);
            line-height: 1;
        }
    }
}
