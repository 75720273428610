.action-item {
    padding: 1em 0;
    border-bottom: 1px solid var(--color-gray-400);
    display: flex;
    align-items: center;
    gap: 1em;

    .user-image {
        height: 3.755em;
        width: 3.75em;
        border-radius: 50%;
        background-color: var(--color-gray-200);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .action-info {
        flex: 1;

        .user-name {
            color: var(--color-gray-700);
            text-transform: capitalize;
        }

        .info {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            gap: 0.75em;

            .title {
                color: var(--color-gray-600);
            }

            .time-since {
                color: var(--color-gray-500);
                width: 100%;
                max-width: fit-content;
            }
        }
    }
}
