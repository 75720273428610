.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .input {
        padding: 0.75em;
        font-size: 1em;
        line-height: 1.5em;
        color: var(--color-gray-900);
        height: 3em;
        width: 100%;
        border: 2px solid var(--color-gray-400);
        transition: 0.2s;
        outline: none;

        &::placeholder {
            color: var(--color-gray-500);
        }

        &:-ms-input-placeholder {
            color: var(--color-gray-500);
        }

        &::-ms-input-placeholder {
            color: var(--color-gray-500);
        }

        &:disabled {
            background-color: var(--color-gray-400);
        }

        &-left-padded {
            padding-left: 2.75em;
        }

        &-right-padded {
            padding-right: 2.75em;
        }

        &:focus,
        &:hover {
            border: 2px solid var(--color-blue-400);
        }
    }

    .input-text {
        font-weight: 500;
        font-size: 0.875em;
        line-height: 1.5em;
    }

    :global {
        .input-info {
            color: var(--color-gray-500);
        }

        .input-error {
            color: var(--color-dark-red);
        }

        .input-warning {
            color: var(--color-dark-yellow);
        }
    }

    .icon {
        position: absolute;
        font-size: 1.5em;

        &-left {
            top: 0.35em;
            left: 0.625em;
            color: var(--color-gray-400);
        }

        &-right {
            top: 0.4em;
            right: 0.625em;
            color: var(--color-gray-300);
        }

        &-clickable {
            cursor: pointer;
            transition: 0.3s ease;
            color: var(--color-gray-400);

            &:hover {
                filter: brightness(85%);
            }
        }

        &-error {
            color: var(--color-danger);
        }
    }
}
