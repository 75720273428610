@import 'https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap';

/* stylelint-disable-next-line */
@import '../vendor/react-arsenal/styles/_base.scss';

:root {
    --color-gray: #d4d6d8;
    --color-primary: #006eb5;
    --color-background: #f2f5f7;
    --color-white: #fff;
    --color-black: #000;

    // Grays
    --color-gray-100: #fafafa;
    --color-gray-200: #f7f7f7;
    --color-gray-300: #edeff0;
    --color-gray-400: #d4d6d8;
    --color-gray-500: #757b83;
    --color-gray-600: #55606e;
    --color-gray-700: #232e3d;

    // Blues
    --color-blue-100: #b5d5f5;
    --color-blue-200: #94c4f5;
    --color-blue-300: #6babeb;
    --color-blue-400: #4f95dd;
    --color-blue-500: #3288ce;
    --color-blue-600: #006eb5;
    --color-blue-700: #1f5a95;

    // Warning
    --color-light-yellow: #ffe17e;
    --color-yellow: #ffeb00;
    --color-dark-yellow: #fbc412;

    // Error
    --color-light-red: #ffbcb7;
    --color-red: #ee402d;
    --color-dark-red: #d12800;

    // Success
    --color-light-green: #b8ecb6;
    --color-green: #6de354;
    --color-dark-green: #59ba47;

    // Azures
    --color-light-azure: #a2daf3;
    --color-azure: #60d4f2;
    --color-dark-azure: #00c1ff;

    // Accents
    --color-accent-1: #f49e4c;
    --color-accent-2: #cf4d6f;
    --color-accent-3: #93e5ab;
    --color-accent-4: #9aa899;
    --color-accent-5: #aa3e98;
    --color-accent-6: #4285f4;
    --color-accent-7: #d5c6e0;
    --color-accent-8: #9d69a3;
    --color-accent-9: #1e2019;
    --color-accent-10: #f5dd90;
}

* {
    margin: 0;
    padding: 0;
    font-family: Inter, 'open_sauce_sans', serif;

    @media only screen and (max-width: 991px) {
        font-size: 14px;
    }

    @media only screen and (max-width: 719px) {
        font-size: 13px;
    }

    @media only screen and (max-width: 479px) {
        font-size: 12px;
    }
}

a {
    text-decoration: none;
}
