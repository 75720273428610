.pie-container {
    padding: 5px 0 15px;
    width: 320px;
    height: 390px;
    background: var(--color-white);
    box-shadow: 0 2px 4px -2px rgb(54 58 62 / 6%);

    @media only screen and (max-width: 412px) {
        width: 300px;
    }

    .pie-title {
        margin-top: 1.5rem;
        margin-left: 1.5rem;
    }
}
