.dropzone {
    padding: 0;
    border: none;

    &:hover {
        border: none;
    }

    .dropzone-content {
        display: flex;
        align-items: center;
        gap: 1em;

        .upload-icon-container {
            background-color: #1a2c8714;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 2em;
            width: 2em;

            .upload-icon {
                font-size: 1em;
                color: var(--color-blue-700);
            }
        }

        .upload-title {
            color: var(--color-blue-700);
        }
    }
}

.file-preview-container {
    margin-top: 1.25em;
    width: fit-content;
    border: 1px solid var(--color-gray-400);
    padding: 0.5em 1em;
    border-radius: 0.25em;
    background-color: var(--color-gray-100);
    display: flex;
    align-items: center;
    gap: 4em;

    .file-name {
        color: var(--color-blue-600);
    }

    .close-icon {
        cursor: pointer;
        color: var(--color-gray-600);
    }
}
