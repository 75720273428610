.change-password-card {
    background-color: var(--color-white);
    padding: 2.5em 2em;
    border-radius: 0.5em;

    .form-wrapper {
        margin-top: 2em;

        .error-message,
        .error-message * {
            color: var(--color-dark-red);
            text-align: center;
            margin-top: 1rem;
        }

        .input-wrapper {
            display: flex;
            flex-direction: column;
            gap: 1.25em;

            .label {
                margin-bottom: 0.5em;
                color: var(--color-gray-500);
                font-weight: 400;
            }

            .input {
                border-radius: 0.5em;
            }

            .select-wrapper {
                .selectLabel {
                    margin-bottom: 0.5em;
                    color: var(--color-gray-500);
                    font-weight: 400;
                }

                .select {
                    .select-control {
                        height: 3em;
                        border-radius: 0.5em;
                        border: 2px solid var(--color-gray-400);
                    }
                }
            }

            :global {
                .disabled {
                    background-color: var(--color-gray-300);
                    border-radius: 0.5em;
                    filter: unset;
                    pointer-events: none;
                }
            }
        }

        .save-button {
            margin-top: 2em;
            width: 100%;
        }
    }
}
