.container {
    flex: 1;
    background-color: var(--color-white);
    padding: 2em;
    box-shadow: 0 2px 4px -2px #363a3e0f;
    border-radius: 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header {
        display: flex;
        justify-content: space-between;

        .logo {
            height: 6em;
        }
    }

    .form-wrapper {
        width: 500px;
        margin: 0 auto;

        .heading {
            color: var(--color-gray-700);
            margin-bottom: 0.5em;
        }

        .sub-heading {
            color: var(--color-gray-500);
            margin-bottom: 2em;
        }

        .error-message,
        .error-message * {
            color: var(--color-dark-red);
            text-align: center;
            margin-top: 1rem;
        }

        .input-wrapper {
            display: flex;
            flex-direction: column;
            gap: 1.25em;

            .label {
                margin-bottom: 0.5em;
                color: var(--color-gray-500);
                font-weight: 400;
            }

            .input {
                border-radius: 0.5em;
            }
        }

        .msg-wrapper {
            margin-top: 0.5em;
            display: flex;
            justify-content: flex-end;

            .forgot-link {
                color: var(--color-blue-700);
            }
        }

        .auth-button {
            margin-top: 2.25em;
            width: 100%;
        }
    }

    .partners-wrapper {
        width: 100%;
        height: 4em;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 3.75em;
        margin-bottom: 2em;

        .partner-logo {
            height: 100px;
        }

        .unicef-logo {
            height: 120px;
        }

        .un-women-logo {
            height: 55px;
        }
    }
}
