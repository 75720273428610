.pie-container {
    height: 390px;
    width: 320px;
    background: var(--color-white);
    box-shadow: 0 2px 4px -2px rgb(54 58 62 / 6%);

    @media only screen and (max-width: 412px) {
        width: 300px;
    }

    .pie-title {
        margin-top: 1.5rem;
        margin-left: 1.5rem;
    }

    .center-text {
        font-size: 28px;
        font-weight: 700;
        fill: var(--color-gray-600);

        .completed-text {
            font-size: 16px;
            fill: #696969;
        }
    }

    .legend {
        display: flex;
        flex-flow: row wrap;
        list-style: none;
        align-items: center;
        padding-left: 3rem;
        justify-content: center;

        .item {
            display: flex;
            flex: 50%;
            gap: 0.5rem;
            align-items: center;
            height: 1.5rem;

            .color-box {
                border-radius: 4px;
                height: 16px;
                width: 16px;
            }

            .item-title {
                color: var(--color-gray-600);
            }
        }
    }
}
