.header {
    background-color: var(--color-white);
    box-shadow: 0 2px 4px -2px #363a3e0f;
    height: 4em;
    position: fixed;
    top: 0;
    left: 15rem;
    width: calc(100% - 15rem);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.25em;
    z-index: 2;

    @media only screen and (max-width: 720px) {
        width: 100%;
        left: 0%;
    }
}

.dropdown-wrapper {
    .button {
        background-color: var(--color-white);
        padding: 0.5em 1em;
        border: 1px solid var(--color-gray-400);
        border-radius: 0.25em;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 1em;

        .title {
            color: var(--color-gray-700);
        }
    }
}

.dropdown {
    margin-top: 0.25em;
    background-color: var(--color-white);

    .region-dropdown {
        padding: 1em;
        width: max-content;
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        border-radius: 0.5em;

        .region-item {
            cursor: pointer;
            padding: 0.75em;
            border-radius: 0.25em;

            &:hover {
                background-color: var(--color-gray-200);
            }
        }
    }

    .clear-item {
        margin-top: -0.5em;
        cursor: pointer;
        padding: 0.75em;
        border-radius: 0.25em;
        text-align: center;
        background-color: var(--color-gray-200);

        &:hover {
            background-color: var(--color-gray-200);
        }
    }
}
