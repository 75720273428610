.container {
    height: calc(100vh - 4.1em);
    padding-top: 4em;

    .tabs {
        display: flex;
        height: 100%;

        .tabs-header {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            width: 275px;
            border-right: 1px solid #dde1e5;
            border-bottom: none;
            margin-top: 46px;

            .header-item {
                padding: 0.75rem;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 0.625rem;
                transition: background-color 0.3s ease;
                color: var(--color-gray-600);
                margin-right: -2px;

                .tab-title {
                    font-size: 1em;
                    font-weight: 600;
                    line-height: 1.5em;
                }

                &-active {
                    border-right: 4px solid var(--color-blue-700);
                    color: var(--color-blue-700);
                }

                &:hover:not(.header-item-active) {
                    color: var(--color-blue-600);
                }
            }
        }

        .content-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 6.5rem 0;
            height: 100%;
            overflow-y: auto;

            @media screen and (max-width: 767px) {
                padding: unset;
            }

            .tab-content {
                width: 524px;
                max-width: 100%;
            }
        }
    }
}
