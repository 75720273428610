.beneficiaries-container {
    padding: 1.5rem;
    background-color: var(--color-white);
    max-width: 1300px;

    .heading {
        color: var(--color-gray-700);
        margin-bottom: 1.5rem;
    }

    .beneficiaries-wrapper {
        display: flex;
        flex-flow: row wrap;
        gap: 1rem;

        .beneficiaries-item {
            width: calc(33.33% - 0.7rem);
            display: flex;
            flex-direction: column;
            background-color: var(--color-background);
            padding: 1rem;
            min-height: 312px;
            border-radius: 4px;

            .heading {
                text-transform: uppercase;
            }

            .title-wrapper {
                display: flex;
                flex-direction: column;
                gap: 0.75em;
                margin-bottom: 1rem;

                .id-wrapper {
                    background-color: var(--color-blue-600);
                    min-width: 28px;
                    width: fit-content;
                    height: 28px;
                    border-radius: 0.25em;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .item-id {
                        color: var(--color-background);
                    }
                }

                .item-title {
                    width: fit-content;
                    color: var(--color-gray-700);
                }
            }

            .content-wrapper {
                display: flex;
                flex-direction: column;
                gap: 0.75rem;
                list-style: none;

                .content-data {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    border-bottom: 1px solid #dde1e5;
                    padding-bottom: 0.5em;

                    &:last-child {
                        border-bottom: unset;
                    }

                    .beneficiaries-key {
                        color: var(--color-gray-600);
                    }

                    .beneficiaries-value {
                        color: var(--color-gray-600);
                    }
                }
            }

            @media only screen and (max-width: 992px) {
                width: calc(50% - 0.7rem);
            }
        }
    }
}
