.container {
    margin: 2.75em -1.25em 0;

    .header {
        background-color: var(--color-blue-600);
        margin-top: 2.75em;
        padding: 4.375em 1.25em 6.875em;

        .inputContainer {
            max-width: 45em;
            margin: auto;

            .input {
                background-color: var(--color-white);
                border-radius: 3.75em;
                padding: 0.625em 3em;
                border: none;
            }
        }
    }

    .topic-wrapper {
        margin: -70px auto;
        width: fit-content;
        background-color: var(--color-white);
        display: flex;
        align-items: center;
        box-shadow: 0 2px 4px -2px #363a3e0f;
        border-radius: 0.25em;
        overflow: hidden;

        @media only screen and (max-width: 480px) {
            margin: -50px auto;
        }

        .resource-item {
            cursor: pointer;
            height: 140px;
            width: 180px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1em;
            border-left: 1px solid var(--color-gray-300);

            @media only screen and (max-width: 1040px) {
                width: 15vw;
            }

            @media only screen and (max-width: 720px) {
                width: 20vw;
            }

            @media only screen and (max-width: 480px) {
                width: 24vw;
                height: 100px;
            }

            .title {
                color: var(--color-gray-600);
            }

            &:hover,
            &.active {
                .iconStroke {
                    path {
                        stroke: var(--color-blue-500);
                    }
                }

                .iconFill {
                    path {
                        fill: var(--color-blue-500);
                    }
                }

                .title {
                    color: var(--color-blue-500);
                }
            }

            &:first-child {
                border-left: unset;
            }
        }
    }

    .resource-wrapper {
        max-width: 1100px;
        margin: 120px auto 0;
        display: flex;
        flex-wrap: wrap;
        gap: 1.5em;
        padding: 0 1.25em 3em;

        @media only screen and (max-width: 480px) {
            margin: 80px auto 0;
        }
    }
}
