.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;

    .details {
        white-space: pre-wrap;
    }

    .reloadButton {
        margin-top: 1em;
    }
}
