.back-button {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-bottom: 0.75em;
    cursor: pointer;

    .title {
        color: var(--color-gray-600);
    }

    .icon {
        color: var(--color-gray-600);
        font-size: 1.25em;
    }
}

.login-box {
    background-color: var(--color-white);
    padding: 2.5em 2em;
    box-shadow: 0 2px 4px -2px #363a3e0f;
    border-radius: 0.5em;
    width: 32.125em;

    .heading {
        color: var(--color-gray-700);
        margin-bottom: 0.5em;
    }

    .sub-heading {
        color: var(--color-gray-500);
    }

    .form-wrapper {
        padding-top: 2em;

        .error-message,
        .error-message * {
            color: var(--color-dark-red);
            text-align: center;
            margin-top: 1rem;
        }

        .input-wrapper {
            display: flex;
            flex-direction: column;
            gap: 1.25em;

            .label {
                margin-bottom: 0.5em;
                color: var(--color-gray-500);
                font-weight: 400;
            }

            .input {
                border-radius: 0.5em;
            }
        }

        .auth-button {
            margin-top: 2.25em;
            width: 100%;
        }
    }
}
