.main {
    height: 100vh;
    padding: 1.25rem;
    background-color: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
        position: absolute;
        top: 1.25em;
        left: 1.25em;
        color: var(--color-blue-700);
    }

    .login-button-wrapper {
        position: absolute;
        top: 1.25em;
        right: 1.25em;

        .login-button {
            padding: 0.5em 1.5em;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .img {
            max-height: 450px;
        }

        .heading {
            margin-bottom: 24px;
            color: var(--color-blue-700);
            font-size: 3.875em;
            line-height: 1.2;
            text-align: center;
        }

        .desc {
            color: var(--color-gray-500);
            margin-bottom: 1.5em;
            text-align: center;
        }

        .button {
            border: 1px solid var(--color-blue-600);
            background-color: transparent;
            color: var(--color-blue-600);
            padding: 0.75em 2.5em;

            &:hover {
                background-color: var(--color-blue-600);
                color: var(--color-white);
            }
        }
    }
}
