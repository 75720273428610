.container {
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    border: 1px solid var(--color-gray-200);
    border-bottom-width: 0;
    box-shadow: 0 8px 8px -4px rgb(16 24 40 / 3%), 0 20px 24px -4px rgb(16 24 40 / 8%);
    background-color: var(--color-white);
    border-radius: 0.5em;
    overflow: hidden;
    z-index: 9999;
    animation: slide-in 0.3s cubic-bezier(0, 0.96, 0.17, 0.99) forwards;

    .content {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 420px;
        max-width: 90vw;

        :global {
            .success {
                color: var(--color-success);
            }

            .danger {
                color: var(--color-danger);
            }

            .warning {
                color: var(--color-warning);
            }

            .neutral {
                color: var(--color-red);
            }
        }

        .message {
            padding-top: 0.25rem;
            padding-right: 1rem;
            font-weight: 400;
            margin-left: 0.5em;
        }

        .close-container {
            flex: 0 0 2rem;
            margin-left: auto;
            background-color: var(--color-gray-200);
            transition: 0.2s ease-out;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            .close {
                color: var(--color-gray-600);
                cursor: pointer;
            }

            &:hover {
                filter: brightness(90%);
            }
        }
    }

    .timer-progress {
        height: 0.25em;
        background-color: var(--color-primary-600);
        animation-name: progress-countdown;
        animation-fill-mode: forwards;
    }
}

@keyframes slide-in {
    from {
        transform: translateX(100%);
        opacity: 0.25;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes progress-countdown {
    from {
        width: 100%;
    }

    to {
        width: 0;
    }
}
