.card {
    width: calc(33.33% - 1em);
    background-color: var(--color-white);
    border-radius: 0.25em;
    box-shadow: 0 2px 4px -2px #363a3e0f;
    padding: 1.5em;

    @media only screen and (max-width: 720px) {
        width: calc(50% - 0.75em);
    }

    @media only screen and (max-width: 480px) {
        width: 100%;
    }

    .title {
        color: var(--color-gray-600);
    }

    .description {
        color: var(--color-gray-600);
        margin-top: 1em;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        max-height: 4.5em;
    }

    .download-button {
        margin-top: 1.5em;
    }
}
