.profile-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border-radius: 8px;
    position: relative;
    cursor: pointer;

    &:hover {
        background-color: var(--color-background);
    }

    .user-name {
        height: 2.625em;
        width: 2.625em;
        border-radius: 50%;
        margin-right: 1rem;
        background-color: var(--color-accent-6);
        display: flex;
        align-items: center;
        justify-content: center;

        .first-letter {
            color: var(--color-white);
        }
    }

    .name {
        color: var(--color-gray-600);
        margin-right: 1.875rem;
    }
}

.dropdown {
    border-radius: 0.25em;
    box-shadow: 0 2px 4px -2px #363a3e0f;
    background-color: var(--color-background);
    cursor: pointer;
    padding: 0.25em 0;

    .dropdown-list {
        display: flex;
        background-color: var(--color-background);
        color: var(--color-gray-600);
        padding: 0.75em 1.5em;

        .logout-text {
            color: var(--color-gray-600);
            margin-left: 0.625rem;
        }
    }
}
